.full-game-info {
    width: 60%;
    margin-left: 20%;
    margin-bottom: 10px;
    padding: 10px 0;
    background-color: white;
    position: relative;
    display: inline-block;
}

.game-image {
    width: 20%;
    margin: 5px 50px;
    float: left;
}

.game-image img {
    width: 100%;
    height: 100%;
}

.full-game-info-text {
    width: 60%;
    float: left;
    overflow: hidden;
    line-height: 1.5em;
    white-space: pre-wrap;
}

@media (min-width: 320px) and (max-width: 1024px) {
    .full-game-info {
        width: 100%;
        margin-left: 0%;
    }
    .game-image {
        margin: 5px 10px;
    }
}

@media (min-width: 1025px) and (max-width: 1250px) {
    .full-game-info {
        width: 80%;
        margin-left: 10%;
    }
    .game-image {
        margin: 5px 20px;
    }
}

@media (min-width: 1921px) {
    .full-game-info {
        width: 50%;
        margin-left: 25%;
    }
}