.monthPanel {
    width: 60%;
    height: 50px;
    margin-top: 10px;
    background-color: white;
    color: black;
    position: relative;
    margin-left: 20%;
    margin-bottom: 10px;
}

.monthPanel ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    list-style: none;
}

.selectMonth {
    border-bottom: 5px solid #473184;
}

.monthPanel button {
    width: 100%;
    font-size: 20pt;
    font-weight: 600;
}


@media (min-width: 320px) and (max-width: 1024px) {
    .monthPanel {
        width: 100%;
        margin-left: 0%;
    }
}

@media (min-width: 1025px) and (max-width: 1250px) {
    .monthPanel {
        width: 80%;
        margin-left: 10%;
    }
}

@media (min-width: 1921px) {
    .monthPanel {
        width: 50%;
        margin-left: 25%;
    }
}
.dayPanel {
    width: 60%;
    margin-left: 20%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
    margin-bottom: 10px;
}

.menu-item {
    width: 60px;
    height: 60px;
    margin: 0 10px;
    cursor: pointer;
    background-color: #473184;
    color: white;
    text-align: center;
}

.menu-item p {
    padding-top: 20px;
}

.menu-item.active {
    box-sizing: border-box;
    border-bottom: 8px #1e9ecb solid;
}

.menu-item-wrapper {
    outline: none !important;
}

.scroll-menu-arrow {
    padding: 20px;
    cursor: pointer;
    color: #473184;
}

.rotate-class {
    transform: rotate(180deg);
}

@media (min-width: 320px) and (max-width: 1024px) {
    .dayPanel {
        width: 100%;
        margin-left: 0%;
    }
}

@media (min-width: 1025px) and (max-width: 1250px) {
    .dayPanel {
        width: 80%;
        margin-left: 10%;
    }
}

@media (min-width: 1921px) {
    .dayPanel {
        width: 50%;
        margin-left: 25%;
    }
}
.newGameForm {
    margin-top: 10px;
    display: inline-block;
    width: 90%;
}

.header-text {
    font-size: 16pt;
    font-weight: 600;
    margin-left: 50px;
    margin-bottom: 10px;
}

.img-button-box {
    float: left;
    margin-left: 50px;
    margin-right: 50px;
    max-width: 300px;
}

.img-button-box canvas {
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 300px;
}

.text-input {
    float: right;
    width: 100%;
    max-width: calc(100% - 400px);
}

.input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.input-file+label {
    font-size: 20pt;
    font-weight: 700;
    color: #7f7f7f;
    background-color: #f2f2f4;
    width: 300px;
    height: 300px;
    cursor: pointer;
    padding-top: 25%;
}

.form-div {
    margin: 10px 0px;
}

.btn-submit {
    color: white;
    background-color: #473184;
    border-radius: 20px;
    padding: 5px 20px;
}

.btn-submit:disabled {
    background-color: #7f7f7f;
}

.btn-cancel {
    color: white;
    background-color: red;
    border-radius: 20px;
    padding: 5px 20px;
}

.btn-add-img {
    color: white;
    background-color: #3dbbed;
    border-radius: 20px;
    padding: 5px 20px;
    cursor: pointer;
}

.form-div input {
    color: #7f7f7f;
    background-color: #f2f2f4;
    padding: 5px;
    border: 1px solid #7f7f7f;
    width: 100%;
}

.form-div textarea {
    color: #7f7f7f;
    background-color: #f2f2f4;
    padding: 5px;
    border: 1px solid #7f7f7f;
    width: 100%;
}

.add-game-input-range {
    width: 100%;
}

.drop-down-menu {
    width: 100%;
}
.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container {
    width: 100%;
}

@media (min-width: 320px) and (max-width: 1024px) {
    .img-button-box {
        max-width: 200px;
    }
    .img-button-box canvas {
        max-width: 200px;
        max-height: 200px;
    }
    .text-input {
        max-width: calc(100% - 300px);
    }
}

@media (min-width: 1025px) and (max-width: 1250px) {
    .img-button-box {
        max-width: 250px;
    }
    .img-button-box canvas {
        max-width: 250px;
        max-height: 250px;
    }
    .text-input {
        max-width: calc(100% - 350px);
    }
}

@media (min-width: 1921px) {
    .img-button-box {
        max-width: 500px;
    }
    .img-button-box canvas {
        max-width: 500px;
        max-height: 500px;
    }
    .text-input {
        max-width: calc(100% - 600px);
    }
}
.checkbox {
    display: inline;
    margin-left: 1em;
    transform: scale(1.3);
}


.full-game-info {
    width: 60%;
    margin-left: 20%;
    margin-bottom: 10px;
    padding: 10px 0;
    background-color: white;
    position: relative;
    display: inline-block;
}

.game-image {
    width: 20%;
    margin: 5px 50px;
    float: left;
}

.game-image img {
    width: 100%;
    height: 100%;
}

.full-game-info-text {
    width: 60%;
    float: left;
    overflow: hidden;
    line-height: 1.5em;
    white-space: pre-wrap;
}

@media (min-width: 320px) and (max-width: 1024px) {
    .full-game-info {
        width: 100%;
        margin-left: 0%;
    }
    .game-image {
        margin: 5px 10px;
    }
}

@media (min-width: 1025px) and (max-width: 1250px) {
    .full-game-info {
        width: 80%;
        margin-left: 10%;
    }
    .game-image {
        margin: 5px 20px;
    }
}

@media (min-width: 1921px) {
    .full-game-info {
        width: 50%;
        margin-left: 25%;
    }
}
.postponed-game {
    z-index: 100;
    font-size: 4em;
    color: red;
    position: absolute;
    top: 25%;
    border: 5px solid;
    padding: 50px;
    transform: rotate(345deg);
}

.games-list {
    width: 60%;
    margin-left: 20%;
    margin-bottom: 5px;
    padding: 10px 0;
    background-color: white;
    position: relative;
    display: inline-block;
}

.OrgLogo {
    width: 20%;
    margin: 5px 50px;
    float: left;
}

.OrgLogo img {
    width: 100%;
    height: 100%;
    border-radius: 15%;
}

.game-info-text {
    float: left;
    width: 60%;
    overflow: hidden;
    line-height: 1.5em;
}

.org-name {
    font-size: 20pt;
    font-weight: 700;
}

.game-theme {
    font-size: 20pt;
    font-weight: 700;
}

.location {
    font-size: 14pt;
    font-style: italic;
}

.count-of-players {
    font-size: 14pt;
    font-style: italic;
}

.time {
    font-size: 14pt;
    color: #7f7f7f;
}

.clock-img {
    width: 20px;
    height: 20px;
    margin: 0px 10px 0px 0px;
}

.trash-icon {
    position: absolute;
    right: 15px;
    bottom: 15px;
    color: #88898b;
}

.trash-icon:hover {
    transition: 1s;
    color: #c20c18;
}

.edit-icon {
    position: absolute;
    right: 40px;
    bottom: 15px;
    color: #88898b;
}

.edit-icon:hover {
    transition: 1s;
    color: #26292e;
}

.arrow {
    position: absolute;
    right: 15px;
    top: 5px;
    color: #88898b;
}

.arrow:hover {
    transition: 1s;
    color: #26292e;
}

.copy-icon {
    position: absolute;
    right: 70px;
    bottom: 15px;
    color: #88898b;
}

.copy-icon:hover {
    transition: 1s;
    color: #26292e;
}

.open-game {
    transition: 2s;
}

.close-game {
    transform: rotate(180deg);
    transition: 2s;
}

.online-game-icon {
    position: absolute;
    right: 40px;
    top: 12px;
    color: #88898b;
}

@media (min-width: 320px) and (max-width: 1024px) {
    .games-list {
        width: 100%;
        margin-left: 0%;
    }
    .OrgLogo {
        margin: 5px 10px;
    }
}

@media (min-width: 1025px) and (max-width: 1250px) {
    .games-list {
        width: 80%;
        margin-left: 10%;
    }
    .OrgLogo {
        margin: 5px 20px;
    }
}

@media (min-width: 1921px) {
    .games-list {
        width: 50%;
        margin-left: 25%;
    }
}
.none-game {
    width: 60%;
    padding-top: 50px;
    margin-left: 20%;
    color: #7f7f7f;
    position: relative;
    display: inline-block;
}

@media (min-width: 320px) and (max-width: 1024px) {    
    .none-game {
        width: 100%;
        margin-left: 0%;
    }
}

@media (min-width: 1025px) and (max-width: 1250px) {
    .none-game {
        width: 80%;
        margin-left: 10%;
    }
}

@media (min-width: 1921px) {
    .none-game {
        width: 50%;
        margin-left: 25%;
    }
}
.add-game-panel {
    width: 60%;
    margin-left: 20%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: white;
}

.add-game-button {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.add-button {
    width: 50px;
    height: 40px;
    background-color: #f2f2f4;
    color: #7f7f7f;
    border: 2px solid #d0cece;
    border-radius: 10px;
    margin-right: 10px;
}

@media (min-width: 320px) and (max-width: 1024px) {
    .add-game-panel {
        width: 100%;
        margin-left: 0%;
    }
}

@media (min-width: 1025px) and (max-width: 1250px) {
    .add-game-panel {
        width: 80%;
        margin-left: 10%;
    }
}

@media (min-width: 1921px) {
    .add-game-panel {
        width: 50%;
        margin-left: 25%;
    }
}
.header {
    position: relative;
    background-color: #473184;
    color: white;
    height: 50px;
    width: 100%;
}

.logo {
    display: inline-block;
    padding: 5px;
    margin-left: 20%;
}

.logo img {
    width: 100%;
    height: 100%;
    max-width: 40px;
    max-height: 40px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 10px;
}

.app-store {
    display: inline;
    padding: 5px;
}

.google-icon {
    display: inline;
    padding: 5px;
}

.google-play-icon {
    font-size: 16pt;
    height: 2.5em;
    width: 6em;
}

.apple-store-icon {
    font-size: 16pt;
    height: 2.5em;
    width: 6em;
}

.header ul {
    position: absolute;
    top: 10px;
    right: 20%;
    list-style: none;
}

.header ul li {
    display: inline-block;
}

.no-style {
    width: 100%;
    height: 100%;
    max-height: 50px;
    min-height: 20px;
    max-width: 200px;
    min-width: 100px;
    border: 0px;
    background-color: none;
    color: white;
    font-weight: 400;
    font-size: 14pt;
}

.no-style span:hover {
    border-bottom: 3px solid white;
    transition: 200ms;
}


@media (min-width: 320px) and (max-width: 1024px) {
    .logo {
        margin-left: 0%;
    }
    .header ul {
        right: 0%;
    }
}

@media (min-width: 1025px) and (max-width: 1250px) {
    .logo {
        margin-left: 10%;
    }
    .header ul {
        right: 10%;
    }
}

@media (min-width: 1921px) {
    .logo {
        margin-left: 25%;
    }
    .header ul {
        right: 25%;
    }
}
.range-input {
    width: 300px;
}

.avatar-editor {
    margin: 0% calc(50% - 150px);
}
footer {
    background-color: #473184;
    width: 100%;
    color: white;
    position: absolute;
    bottom: 0;
}

footer .app-logo-footer {
    margin: 5px 0px 0px calc(20% + 5px);
    max-width: 40px;
    max-height: 40px;
    border-radius: 10px;
}

footer ul {
    list-style: none;
    float: right;
    margin: 0;
    padding: 0;
    margin-right: 20%;
}

footer ul li {
    display: inline-block;
    margin: 5px 5px 0 5px;
}

footer .app-mail {
    font-size: 16pt;
}

footer .gmail-icon {
    font-size: 16pt;
    height: 2em;
    width: 2em;
}

footer .google-play-icon {
    font-size: 16pt;
    height: 1.5em;
    width: 4.5em;
}

footer hr {
    margin: 5px 20% 0 20%;
    width: 60%;
    border: none;
    background-color: grey;
    color: grey;
    height: 1px;
}

footer .app-slogan {
    display: inline;
    margin-left: calc(20% + 5px);
}

footer .app-year {
    float: right;
    margin-right: calc(20% + 5px);
}

@media (min-width: 320px) and (max-width: 1024px) {
    footer .app-logo-footer {
        margin: 5px 0px 0px 5px;
    }
    footer ul {
        margin-right: 0%;
    }
    footer .app-mail {
        font-size: 14pt;
    }
    footer hr {
        margin-left: 0%;
        width: 100%;
    }
    footer .app-slogan {
        margin-left: 5px;
    }
    footer .app-year {
        margin-right: 5px;
    }
}

@media (min-width: 1025px) and (max-width: 1250px) {
    footer .app-logo-footer {
        margin: 5px 0px 0px calc(10% + 5px);
    }
    footer ul {
        margin-right: 10%;
    }
    footer hr {
        margin-left: 10%;
        width: 80%;
    }
    footer .app-slogan {
        margin-left: 10%;
    }
    footer .app-year {
        margin-right: calc(10% + 5px);
    }
}

@media (min-width: 1921px) {
    footer .app-logo-footer {
        margin: 0px 0px 0px calc(25% + 5px);
    }
    footer ul {
        margin-right: 25%;
    }
    footer hr {
        margin-left: 25%;
        width: 50%;
    }
    footer .app-slogan {
        margin-left: 25%;
    }
    footer .app-year {
        margin-right: calc(25% + 5px);
    }
}
