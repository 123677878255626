.monthPanel {
    width: 60%;
    height: 50px;
    margin-top: 10px;
    background-color: white;
    color: black;
    position: relative;
    margin-left: 20%;
    margin-bottom: 10px;
}

.monthPanel ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    list-style: none;
}

.selectMonth {
    border-bottom: 5px solid #473184;
}

.monthPanel button {
    width: 100%;
    font-size: 20pt;
    font-weight: 600;
}


@media (min-width: 320px) and (max-width: 1024px) {
    .monthPanel {
        width: 100%;
        margin-left: 0%;
    }
}

@media (min-width: 1025px) and (max-width: 1250px) {
    .monthPanel {
        width: 80%;
        margin-left: 10%;
    }
}

@media (min-width: 1921px) {
    .monthPanel {
        width: 50%;
        margin-left: 25%;
    }
}