.postponed-game {
    z-index: 100;
    font-size: 4em;
    color: red;
    position: absolute;
    top: 25%;
    border: 5px solid;
    padding: 50px;
    transform: rotate(345deg);
}
