.dayPanel {
    width: 60%;
    margin-left: 20%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
    margin-bottom: 10px;
}

.menu-item {
    width: 60px;
    height: 60px;
    margin: 0 10px;
    cursor: pointer;
    background-color: #473184;
    color: white;
    text-align: center;
}

.menu-item p {
    padding-top: 20px;
}

.menu-item.active {
    box-sizing: border-box;
    border-bottom: 8px #1e9ecb solid;
}

.menu-item-wrapper {
    outline: none !important;
}

.scroll-menu-arrow {
    padding: 20px;
    cursor: pointer;
    color: #473184;
}

.rotate-class {
    transform: rotate(180deg);
}

@media (min-width: 320px) and (max-width: 1024px) {
    .dayPanel {
        width: 100%;
        margin-left: 0%;
    }
}

@media (min-width: 1025px) and (max-width: 1250px) {
    .dayPanel {
        width: 80%;
        margin-left: 10%;
    }
}

@media (min-width: 1921px) {
    .dayPanel {
        width: 50%;
        margin-left: 25%;
    }
}