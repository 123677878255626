.newGameForm {
    margin-top: 10px;
    display: inline-block;
    width: 90%;
}

.header-text {
    font-size: 16pt;
    font-weight: 600;
    margin-left: 50px;
    margin-bottom: 10px;
}

.img-button-box {
    float: left;
    margin-left: 50px;
    margin-right: 50px;
    max-width: 300px;
}

.img-button-box canvas {
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 300px;
}

.text-input {
    float: right;
    width: 100%;
    max-width: calc(100% - 400px);
}

.input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.input-file+label {
    font-size: 20pt;
    font-weight: 700;
    color: #7f7f7f;
    background-color: #f2f2f4;
    width: 300px;
    height: 300px;
    cursor: pointer;
    padding-top: 25%;
}

.form-div {
    margin: 10px 0px;
}

.btn-submit {
    color: white;
    background-color: #473184;
    border-radius: 20px;
    padding: 5px 20px;
}

.btn-submit:disabled {
    background-color: #7f7f7f;
}

.btn-cancel {
    color: white;
    background-color: red;
    border-radius: 20px;
    padding: 5px 20px;
}

.btn-add-img {
    color: white;
    background-color: #3dbbed;
    border-radius: 20px;
    padding: 5px 20px;
    cursor: pointer;
}

.form-div input {
    color: #7f7f7f;
    background-color: #f2f2f4;
    padding: 5px;
    border: 1px solid #7f7f7f;
    width: 100%;
}

.form-div textarea {
    color: #7f7f7f;
    background-color: #f2f2f4;
    padding: 5px;
    border: 1px solid #7f7f7f;
    width: 100%;
}

.add-game-input-range {
    width: 100%;
}

.drop-down-menu {
    width: 100%;
}
.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container {
    width: 100%;
}

@media (min-width: 320px) and (max-width: 1024px) {
    .img-button-box {
        max-width: 200px;
    }
    .img-button-box canvas {
        max-width: 200px;
        max-height: 200px;
    }
    .text-input {
        max-width: calc(100% - 300px);
    }
}

@media (min-width: 1025px) and (max-width: 1250px) {
    .img-button-box {
        max-width: 250px;
    }
    .img-button-box canvas {
        max-width: 250px;
        max-height: 250px;
    }
    .text-input {
        max-width: calc(100% - 350px);
    }
}

@media (min-width: 1921px) {
    .img-button-box {
        max-width: 500px;
    }
    .img-button-box canvas {
        max-width: 500px;
        max-height: 500px;
    }
    .text-input {
        max-width: calc(100% - 600px);
    }
}