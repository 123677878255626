footer {
    background-color: #473184;
    width: 100%;
    color: white;
    position: absolute;
    bottom: 0;
}

footer .app-logo-footer {
    margin: 5px 0px 0px calc(20% + 5px);
    max-width: 40px;
    max-height: 40px;
    border-radius: 10px;
}

footer ul {
    list-style: none;
    float: right;
    margin: 0;
    padding: 0;
    margin-right: 20%;
}

footer ul li {
    display: inline-block;
    margin: 5px 5px 0 5px;
}

footer .app-mail {
    font-size: 16pt;
}

footer .gmail-icon {
    font-size: 16pt;
    height: 2em;
    width: 2em;
}

footer .google-play-icon {
    font-size: 16pt;
    height: 1.5em;
    width: 4.5em;
}

footer hr {
    margin: 5px 20% 0 20%;
    width: 60%;
    border: none;
    background-color: grey;
    color: grey;
    height: 1px;
}

footer .app-slogan {
    display: inline;
    margin-left: calc(20% + 5px);
}

footer .app-year {
    float: right;
    margin-right: calc(20% + 5px);
}

@media (min-width: 320px) and (max-width: 1024px) {
    footer .app-logo-footer {
        margin: 5px 0px 0px 5px;
    }
    footer ul {
        margin-right: 0%;
    }
    footer .app-mail {
        font-size: 14pt;
    }
    footer hr {
        margin-left: 0%;
        width: 100%;
    }
    footer .app-slogan {
        margin-left: 5px;
    }
    footer .app-year {
        margin-right: 5px;
    }
}

@media (min-width: 1025px) and (max-width: 1250px) {
    footer .app-logo-footer {
        margin: 5px 0px 0px calc(10% + 5px);
    }
    footer ul {
        margin-right: 10%;
    }
    footer hr {
        margin-left: 10%;
        width: 80%;
    }
    footer .app-slogan {
        margin-left: 10%;
    }
    footer .app-year {
        margin-right: calc(10% + 5px);
    }
}

@media (min-width: 1921px) {
    footer .app-logo-footer {
        margin: 0px 0px 0px calc(25% + 5px);
    }
    footer ul {
        margin-right: 25%;
    }
    footer hr {
        margin-left: 25%;
        width: 50%;
    }
    footer .app-slogan {
        margin-left: 25%;
    }
    footer .app-year {
        margin-right: calc(25% + 5px);
    }
}