.header {
    position: relative;
    background-color: #473184;
    color: white;
    height: 50px;
    width: 100%;
}

.logo {
    display: inline-block;
    padding: 5px;
    margin-left: 20%;
}

.logo img {
    width: 100%;
    height: 100%;
    max-width: 40px;
    max-height: 40px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 10px;
}

.app-store {
    display: inline;
    padding: 5px;
}

.google-icon {
    display: inline;
    padding: 5px;
}

.google-play-icon {
    font-size: 16pt;
    height: 2.5em;
    width: 6em;
}

.apple-store-icon {
    font-size: 16pt;
    height: 2.5em;
    width: 6em;
}

.header ul {
    position: absolute;
    top: 10px;
    right: 20%;
    list-style: none;
}

.header ul li {
    display: inline-block;
}

.no-style {
    width: 100%;
    height: 100%;
    max-height: 50px;
    min-height: 20px;
    max-width: 200px;
    min-width: 100px;
    border: 0px;
    background-color: none;
    color: white;
    font-weight: 400;
    font-size: 14pt;
}

.no-style span:hover {
    border-bottom: 3px solid white;
    transition: 200ms;
}


@media (min-width: 320px) and (max-width: 1024px) {
    .logo {
        margin-left: 0%;
    }
    .header ul {
        right: 0%;
    }
}

@media (min-width: 1025px) and (max-width: 1250px) {
    .logo {
        margin-left: 10%;
    }
    .header ul {
        right: 10%;
    }
}

@media (min-width: 1921px) {
    .logo {
        margin-left: 25%;
    }
    .header ul {
        right: 25%;
    }
}