.add-game-panel {
    width: 60%;
    margin-left: 20%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: white;
}

.add-game-button {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.add-button {
    width: 50px;
    height: 40px;
    background-color: #f2f2f4;
    color: #7f7f7f;
    border: 2px solid #d0cece;
    border-radius: 10px;
    margin-right: 10px;
}

@media (min-width: 320px) and (max-width: 1024px) {
    .add-game-panel {
        width: 100%;
        margin-left: 0%;
    }
}

@media (min-width: 1025px) and (max-width: 1250px) {
    .add-game-panel {
        width: 80%;
        margin-left: 10%;
    }
}

@media (min-width: 1921px) {
    .add-game-panel {
        width: 50%;
        margin-left: 25%;
    }
}