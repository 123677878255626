.none-game {
    width: 60%;
    padding-top: 50px;
    margin-left: 20%;
    color: #7f7f7f;
    position: relative;
    display: inline-block;
}

@media (min-width: 320px) and (max-width: 1024px) {    
    .none-game {
        width: 100%;
        margin-left: 0%;
    }
}

@media (min-width: 1025px) and (max-width: 1250px) {
    .none-game {
        width: 80%;
        margin-left: 10%;
    }
}

@media (min-width: 1921px) {
    .none-game {
        width: 50%;
        margin-left: 25%;
    }
}