.games-list {
    width: 60%;
    margin-left: 20%;
    margin-bottom: 5px;
    padding: 10px 0;
    background-color: white;
    position: relative;
    display: inline-block;
}

.OrgLogo {
    width: 20%;
    margin: 5px 50px;
    float: left;
}

.OrgLogo img {
    width: 100%;
    height: 100%;
    border-radius: 15%;
}

.game-info-text {
    float: left;
    width: 60%;
    overflow: hidden;
    line-height: 1.5em;
}

.org-name {
    font-size: 20pt;
    font-weight: 700;
}

.game-theme {
    font-size: 20pt;
    font-weight: 700;
}

.location {
    font-size: 14pt;
    font-style: italic;
}

.count-of-players {
    font-size: 14pt;
    font-style: italic;
}

.time {
    font-size: 14pt;
    color: #7f7f7f;
}

.clock-img {
    width: 20px;
    height: 20px;
    margin: 0px 10px 0px 0px;
}

.trash-icon {
    position: absolute;
    right: 15px;
    bottom: 15px;
    color: #88898b;
}

.trash-icon:hover {
    transition: 1s;
    color: #c20c18;
}

.edit-icon {
    position: absolute;
    right: 40px;
    bottom: 15px;
    color: #88898b;
}

.edit-icon:hover {
    transition: 1s;
    color: #26292e;
}

.arrow {
    position: absolute;
    right: 15px;
    top: 5px;
    color: #88898b;
}

.arrow:hover {
    transition: 1s;
    color: #26292e;
}

.copy-icon {
    position: absolute;
    right: 70px;
    bottom: 15px;
    color: #88898b;
}

.copy-icon:hover {
    transition: 1s;
    color: #26292e;
}

.open-game {
    transition: 2s;
}

.close-game {
    transform: rotate(180deg);
    transition: 2s;
}

.online-game-icon {
    position: absolute;
    right: 40px;
    top: 12px;
    color: #88898b;
}

@media (min-width: 320px) and (max-width: 1024px) {
    .games-list {
        width: 100%;
        margin-left: 0%;
    }
    .OrgLogo {
        margin: 5px 10px;
    }
}

@media (min-width: 1025px) and (max-width: 1250px) {
    .games-list {
        width: 80%;
        margin-left: 10%;
    }
    .OrgLogo {
        margin: 5px 20px;
    }
}

@media (min-width: 1921px) {
    .games-list {
        width: 50%;
        margin-left: 25%;
    }
}